@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 640px) {
  .app {
    display: none;
  }
}

.react-table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: #f2f2f2;
  /* font-weight: bold; */
}

.table-row {
  background-color: white;
}

.table-cell {
  padding: 8px;
  border: 1px solid #ddd;
}

.table-cell:first-child {
  border-left: none;
}

.table-cell:last-child {
  border-right: none;
}




body{
  overflow: hidden;
}


@media (max-width: 640px) {
  .table-container {
    overflow-x: auto;
  }

  .react-table {
    width: 100%;
    display: block;
  }

  .table-header,
  .table-row {
    display: block;
  }

  .table-cell {
    text-align: left;
  }

  .table-cell,
  .table-header {
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }

  .table-header {
    font-weight: bold;
  }
}


@media (max-width: 640px) {
  .graph-container {
    overflow-x: auto;
  }

  .text-center {
    display: block;
    text-align: center;
  }

  .inline-block {
    display: block;
    margin: 0 auto;
  }

  .pr-16 {
    padding-right: 0;
  }
}



.sidebar-menu {
  display: flex;
  height: 100vh;
  overflow: hidden;
}





.message-container {
  /* Apply smooth scroll behavior with transition */
  scroll-behavior: smooth;
  transition: scroll-behavior 0.3s ease-in-out;
}
